<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import "@vueform/multiselect/themes/default.css";
import FilterForm from "@/components/dashboard/filter-form.vue";
import API from "@/plugins/axios-method";
import LoadingModal from "@/components/loading-modal.vue";
import {serverUrl} from "@/helpers/constant/serverUrl";
import Spinner from "@/components/spinner.vue";
export default {
  page: {
    title: "Adhérents",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {
    Spinner,
    LoadingModal,
    FilterForm,
    DataLoader,
    UserTableCard,
    Layout,
    PageHeader
  },
  data() {
    return {
      users: [],
      showDataLoader: true,
      page: 1,
      totalItems: 0,
      isSubmitted: false,
      isExportSubmitted: false,
      selectedCitiesString: '',
      memberType: ''
    }
  },
  mounted() {
  },
  methods: {
    submitFilter(payload){
      this.users = []
      this.isSubmitted = true
      this.selectedCitiesString = payload.selectedCitiesString
      this.memberType = payload.memberType
      API.post('/admin/search', payload)
          .then(response => {
            response.data.searchResults[0]?.users.map(u => (
              this.users.push(u)
            ))
          })
          .finally(() => {
            this.isSubmitted = false
          })
    },
    exportUsersToExcel(){
      this.isExportSubmitted = true
      API.get('/export-users-by-search?' + 'memberType=' + this.memberType + '&cityIds=' + this.selectedCitiesString)
          .then(response => {
            const {link} = response.data;
            console.log(link)
            console.log(serverUrl + '/' + link)
            const downloadTag = document.createElement('a');
            downloadTag.target = '_blank';
            downloadTag.href = serverUrl + '/' + link;
            document.body.appendChild(downloadTag);
            downloadTag.click();
            document.body.removeChild(downloadTag);

          }).finally(() => this.isExportSubmitted = false)
    },
    updateLoader(){
      this.showDataLoader = false
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Recherche des adhérents'"
        :show-add-button="false"
        :show-search="false"
    />
    <filter-form @filterSubmitted="submitFilter" @cities-loaded="updateLoader"/>
    <loading-modal v-model="isSubmitted"/>
    <DataLoader v-if="showDataLoader"/>
    <section v-if="users.length > 0 && !showDataLoader">
      <div class="">
        <div class="card">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <h5 class="card-title mb-0">Reacherche</h5>
              </div>
              <div class="col-sm-auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <button :disabled="isExportSubmitted" @click="exportUsersToExcel" class="btn btn-md btn-info" title="exporter vers un fichier excel">
                    <span v-if="!isExportSubmitted">
                      <i class=" ri-download-2-line align-bottom me-1"></i>
                      Excel
                    </span>
                    <spinner v-if="isExportSubmitted"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <UserTableCard
                :users="users"
                :page-name="'members'"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>